import React from "react"
import ReactWOW from "react-wow"

const Partner = () => {
  return (
    <div className="partner-area ptb-70 bg-fafafb">
      <div className="container"></div>
    </div>
  )
}

export default Partner
